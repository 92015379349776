<template>
  <div class="terms-of-service">
    <h1>Conditions d'Utilisation</h1>
    <p class="last-updated">Dernière mise à jour : {{ lastUpdated }}</p>

    <section>
      <h2>1. Acceptation des conditions</h2>
      <p>En accédant et en utilisant les services de Vivre La Rochelle, vous acceptez d'être lié par ces conditions d'utilisation. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser nos services.</p>
    </section>

    <section>
      <h2>2. Description des services</h2>
      <p>Vivre La Rochelle offre des services de photographie, incluant mais non limité à :</p>
      <ul>
        <li>Séances photo individuelles et de groupe</li>
        <li>Photographie d'événements</li>
        <li>Vente de tirages photographiques</li>
      </ul>
    </section>

    <section>
      <h2>3. Réservations et annulations</h2>
      <p>Les réservations sont confirmées après paiement d'un acompte. Les annulations doivent être effectuées au moins 48 heures avant la séance prévue pour un remboursement partiel.</p>
    </section>

    <section>
      <h2>4. Droits d'auteur</h2>
      <p>Toutes les photographies produites par Vivre La Rochelle restent la propriété intellectuelle du photographe. Une licence d'utilisation est accordée au client pour un usage personnel.</p>
    </section>

    <section>
      <h2>5. Utilisation du site web</h2>
      <p>Vous vous engagez à utiliser notre site web de manière légale et à ne pas :</p>
      <ul>
        <li>Violer les droits de propriété intellectuelle</li>
        <li>Diffuser du contenu illégal ou offensant</li>
        <li>Tenter d'accéder de manière non autorisée à nos systèmes</li>
      </ul>
    </section>

    <section>
      <h2>6. Limitation de responsabilité</h2>
      <p>Vivre La Rochelle ne peut être tenu responsable des dommages indirects résultant de l'utilisation de nos services.</p>
    </section>

    <section>
      <h2>7. Modifications des conditions</h2>
      <p>Nous nous réservons le droit de modifier ces conditions à tout moment. Les modifications entrent en vigueur dès leur publication sur notre site.</p>
    </section>

    <section>
      <h2>8. Loi applicable</h2>
      <p>Ces conditions sont régies par la loi française. Tout litige sera soumis à la juridiction exclusive des tribunaux de La Rochelle.</p>
    </section>

    <section>
      <h2>9. Contact</h2>
      <p>Pour toute question concernant ces conditions d'utilisation, veuillez nous contacter à :</p>
      <p>Email : contact@vivrelarochelle.com</p>
      <p>Adresse : 123 Rue de la Photographie, 17000 La Rochelle, France</p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'TermsOfService',
  data() {
    return {
      lastUpdated: '1 juin 2023'
    }
  }
}
</script>

<style scoped>
.terms-of-service {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
  color: #333;
}

h1 {
  font-family: 'Playfair Display', serif;
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 1rem;
}

h2 {
  font-family: 'Playfair Display', serif;
  font-size: 1.8rem;
  color: #34495e;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.last-updated {
  font-style: italic;
  color: #7f8c8d;
  margin-bottom: 2rem;
}

section {
  margin-bottom: 2rem;
}

ul {
  padding-left: 1.5rem;
}

@media (max-width: 768px) {
  .terms-of-service {
    padding: 1rem;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }
}
</style>