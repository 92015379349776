<template>
  <div class="about-page">
    <div class="hero">
      <h1>À propos de VIVRE LA ROCHELLE</h1>
      <p class="subtitle">Capturant l'essence de La Rochelle depuis 2022</p>
    </div>

    <div class="container">
      <section class="about-content">
        <div class="about-text">
          <h2>Notre Histoire</h2>
          <p>Fondé en 2022, VIVRE LA ROCHELLE est bien plus qu'un simple collectif de photographes : c'est une aventure née d'un amour profond pour une ville au caractère unique. Tout a commencé sur les quais du Vieux Port, où les reflets du soleil sur l'eau et les silhouettes des tours médiévales ont inspiré notre première série de clichés. Depuis, nous avons exploré chaque recoin de La Rochelle, des plages dorées aux ruelles pavées, capturant son essence intemporelle et son énergie vibrante.</p>
          <p>Avec chaque photo, nous racontons l'évolution de cette ville dynamique, en mettant en lumière son riche héritage historique tout en célébrant ses innovations modernes. VIVRE LA ROCHELLE est devenu le témoin visuel de l'âme rochelaise, préservant ses instants les plus précieux.</p>
        </div>
        <div class="about-image">
          <img src="https://images.unsplash.com/photo-1589570731442-f2c1a8e0ad7d?ixlib=rb-4.0.3&auto=format&fit=crop&w=1170&q=80" alt="Vue sur le port de La Rochelle" />
        </div>
      </section>

      <section class="mission">
        <h2>Notre Mission</h2>
        <p>Chez VIVRE LA ROCHELLE, nous avons une mission claire : révéler l'âme de La Rochelle à travers des images qui captivent, inspirent et émeuvent. Notre travail repose sur quatre piliers :</p>
        <ul>
          <li>Immortaliser les moments uniques, des scènes du quotidien aux événements qui marquent la vie rochelaise</li>
          <li>Sublimer la beauté naturelle, architecturale et culturelle de la ville</li>
          <li>Raconter les histoires inédites de notre communauté à travers des images qui parlent d'elles-mêmes</li>
          <li>Éveiller l'envie de découvrir ou redécouvrir La Rochelle, que vous soyez résident ou simple visiteur</li>
        </ul>
        <p>À travers notre art, nous voulons que chaque image soit une invitation à explorer, à s'émerveiller et à tomber amoureux de cette ville exceptionnelle.</p>
      </section>

      <section class="team">
        <h2>Notre Équipe</h2>
        <p>L'équipe de VIVRE LA ROCHELLE, ce sont des artistes passionnés, tous profondément enracinés dans le tissu rochelais. Chaque membre apporte son regard unique, son talent et sa maîtrise technique, avec une volonté commune : capturer l'essence vivante de La Rochelle sous toutes ses formes. Ensemble, nous créons des images qui, loin d'être de simples photographies, sont de véritables fenêtres ouvertes sur l'âme de notre ville.</p>
        <div class="team-grid">
          <div class="team-member">
            <img src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=387&q=80" alt="Photo de Marie Dubois" />
            <h3>Marie Dubois</h3>
            <p>Fondatrice & Photographe en chef</p>
          </div>
          <div class="team-member">
            <img src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=387&q=80" alt="Photo de Jean Martin" />
            <h3>Jean Martin</h3>
            <p>Photographe d'architecture</p>
          </div>
          <div class="team-member">
            <img src="https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=388&q=80" alt="Photo de Sophie Leclerc" />
            <h3>Sophie Leclerc</h3>
            <p>Photographe d'événements</p>
          </div>
        </div>
      </section>

      <section class="gallery">
        <h2>Notre Galerie</h2>
        <p>Explorez avec nous cette aventure visuelle et laissez-vous transporter par la magie de La Rochelle.</p>
        <div class="gallery-grid">
          <img src="https://images.unsplash.com/photo-1589570731442-f2c1a8e0ad7d?ixlib=rb-4.0.3&auto=format&fit=crop&w=1170&q=80" alt="Port de La Rochelle" />
          <img src="https://images.unsplash.com/photo-1564594874687-ae5a1d70a1b0?ixlib=rb-4.0.3&auto=format&fit=crop&w=1470&q=80" alt="Plage de La Rochelle" />
          <img src="https://images.unsplash.com/photo-1601921427660-817a2999d06e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1332&q=80" alt="Rue de La Rochelle" />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutPage',
}
</script>

<style scoped>
.about-page {
  background-color: var(--color-background);
  color: var(--color-text);
}

.hero {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://images.unsplash.com/photo-1564594874687-ae5a1d70a1b0?ixlib=rb-4.0.3&auto=format&fit=crop&w=1470&q=80');
  background-size: cover;
  background-position: center;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}

.hero h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.hero .subtitle {
  font-size: 1.2rem;
  font-style: italic;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.about-content {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
}

.about-text {
  flex: 1;
}

.about-image {
  flex: 1;
}

.about-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h2 {
  color: var(--color-accent);
  margin-bottom: 1rem;
  font-size: 1.8rem;
}

.mission ul {
  list-style-type: none;
  padding-left: 0;
}

.mission li {
  padding-left: 1.5rem;
  position: relative;
  margin-bottom: 0.5rem;
}

.mission li::before {
  content: '✓';
  position: absolute;
  left: 0;
  color: var(--color-accent);
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.team-member {
  text-align: center;
  transition: transform 0.3s ease;
}

.team-member:hover {
  transform: translateY(-5px);
}

.team-member img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1rem;
  transition: transform 0.3s ease;
}

.team-member img:hover {
  transform: scale(1.05);
}

.team-member h3 {
  margin-bottom: 0.5rem;
}

.team-member p {
  font-style: italic;
  color: var(--color-text-light);
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
  margin-top: 2rem;
}

.gallery-grid img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1rem;
  transition: transform 0.3s ease;
}

.gallery-grid img:hover {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .about-content {
    flex-direction: column;
  }

  .hero h1 {
    font-size: 2rem;
  }

  .hero .subtitle {
    font-size: 1rem;
  }

  .team-grid, .gallery-grid {
    grid-template-columns: 1fr;
  }
}
</style>