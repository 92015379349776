<template>
    <div class="comment-management-page">
      <h1>Gestion des Commentaires</h1>
      
      <div class="filters">
        <select v-model="filterStatus" @change="filterComments">
          <option value="all">Tous les commentaires</option>
          <option value="pending">En attente</option>
          <option value="approved">Approuvés</option>
          <option value="rejected">Rejetés</option>
        </select>
        <input 
          type="text" 
          v-model="searchQuery" 
          @input="filterComments" 
          placeholder="Rechercher un commentaire..."
        />
      </div>
  
      <table class="comment-table">
        <thead>
          <tr>
            <th>Utilisateur</th>
            <th>Commentaire</th>
            <th>Album/Photo</th>
            <th>Date</th>
            <th>Statut</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="comment in filteredComments" :key="comment.id">
            <td>{{ comment.user }}</td>
            <td>{{ comment.text }}</td>
            <td>{{ comment.target }}</td>
            <td>{{ formatDate(comment.date) }}</td>
            <td>
              <span :class="['status-badge', comment.status]">
                {{ comment.status }}
              </span>
            </td>
            <td>
              <button 
                v-if="comment.status === 'pending'" 
                @click="approveComment(comment.id)" 
                class="approve-btn"
              >
                Approuver
              </button>
              <button 
                v-if="comment.status === 'pending'" 
                @click="rejectComment(comment.id)" 
                class="reject-btn"
              >
                Rejeter
              </button>
              <button @click="deleteComment(comment.id)" class="delete-btn">
                Supprimer
              </button>
            </td>
          </tr>
        </tbody>
      </table>
  
      <div v-if="filteredComments.length === 0" class="no-comments">
        Aucun commentaire trouvé.
      </div>
    </div>
  </template>
  
  <script>
  import { ref, computed } from 'vue'
  
  export default {
    name: 'CommentManagementPage',
    setup() {
      const comments = ref([
        { id: 1, user: 'Jean Dupont', text: 'Superbe photo !', target: 'Album: Bordeaux by Night', date: '2023-05-15T14:30:00', status: 'approved' },
        { id: 2, user: 'Marie Martin', text: 'J\'adore cette vue !', target: 'Photo: Place de la Bourse', date: '2023-05-16T10:15:00', status: 'pending' },
        { id: 3, user: 'Pierre Durand', text: 'Magnifique coucher de soleil', target: 'Album: Côte Basque', date: '2023-05-17T18:45:00', status: 'pending' },
        { id: 4, user: 'Sophie Lefebvre', text: 'Quand a été prise cette photo ?', target: 'Photo: Dune du Pilat', date: '2023-05-18T09:20:00', status: 'rejected' },
      ])
  
      const filterStatus = ref('all')
      const searchQuery = ref('')
  
      const filteredComments = computed(() => {
        return comments.value.filter(comment => {
          const matchesStatus = filterStatus.value === 'all' || comment.status === filterStatus.value
          const matchesSearch = comment.text.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
                                comment.user.toLowerCase().includes(searchQuery.value.toLowerCase())
          return matchesStatus && matchesSearch
        })
      })
  
      const filterComments = () => {
        // Cette fonction est appelée automatiquement grâce aux liaisons v-model et @input
      }
  
      const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' }
        return new Date(dateString).toLocaleDateString('fr-FR', options)
      }
  
      const approveComment = (id) => {
        const comment = comments.value.find(c => c.id === id)
        if (comment) {
          comment.status = 'approved'
        }
      }
  
      const rejectComment = (id) => {
        const comment = comments.value.find(c => c.id === id)
        if (comment) {
          comment.status = 'rejected'
        }
      }
  
      const deleteComment = (id) => {
        comments.value = comments.value.filter(c => c.id !== id)
      }
  
      return {
        comments,
        filteredComments,
        filterStatus,
        searchQuery,
        filterComments,
        formatDate,
        approveComment,
        rejectComment,
        deleteComment
      }
    }
  }
  </script>
  
  <style scoped>
  .comment-management-page {
    padding: 2rem;
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: #2c3e50;
  }
  
  .filters {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  
  .filters select,
  .filters input {
    padding: 0.5rem;
    border: 1px solid #bdc3c7;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .comment-table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .comment-table th,
  .comment-table td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #ecf0f1;
  }
  
  .comment-table th {
    background-color: #34495e;
    color: white;
  }
  
  .status-badge {
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.8rem;
  }
  
  .status-badge.approved {
    background-color: #2ecc71;
    color: white;
  }
  
  .status-badge.pending {
    background-color: #f39c12;
    color: white;
  }
  
  .status-badge.rejected {
    background-color: #e74c3c;
    color: white;
  }
  
  .approve-btn,
  .reject-btn,
  .delete-btn {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-right: 0.5rem;
  }
  
  .approve-btn {
    background-color: #2ecc71;
    color: white;
  }
  
  .approve-btn:hover {
    background-color: #27ae60;
  }
  
  .reject-btn {
    background-color: #e74c3c;
    color: white;
  }
  
  .reject-btn:hover {
    background-color: #c0392b;
  }
  
  .delete-btn {
    background-color: #95a5a6;
    color: white;
  }
  
  .delete-btn:hover {
    background-color: #7f8c8d;
  }
  
  .no-comments {
    text-align: center;
    padding: 2rem;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  </style>