<template>
  <div class="gallery-page">
    <header class="hero">
      <div class="hero-content">
        <h1>Galerie NEO AQUITAINE</h1>
        <p>Découvrez la beauté de la Nouvelle-Aquitaine à travers nos objectifs</p>
      </div>
    </header>

    <div class="container">
      <CategoryNavigation 
        :categories="categories" 
        :selectedCategory="selectedCategory"
        @select-category="selectCategory"
      />

      <div class="albums-grid">
        <div v-for="album in filteredAlbums" :key="album.id" class="album-card" @click="selectAlbum(album)">
          <div class="album-cover">
            <img :src="album.coverImage" :alt="album.name" class="cover-image">
            <div class="album-overlay">
              <h3 class="album-title">{{ album.name }}</h3>
              <p class="album-location">{{ album.location }}</p>
              <p class="album-date">{{ album.date }}</p>
              <span class="photo-count">{{ album.photos.length }} photos</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <AlbumViewer
      v-if="selectedAlbum"
      :album="selectedAlbum"
      @close="closeAlbum"
      @next-album="nextAlbum"
      @previous-album="previousAlbum"
    />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import CategoryNavigation from '@/components/gallery/CategoryNavigation.vue'
import AlbumViewer from '@/components/gallery/AlbumViewer.vue'

const categories = [
  { id: 'all', name: 'Tous' },
  { id: 'landscapes', name: 'Paysages' },
  { id: 'cities', name: 'Villes' },
  { id: 'events', name: 'Événements' },
  { id: 'portraits', name: 'Portraits' },
  { id: 'culture', name: 'Culture' }
]

const albums = ref([
  {
    id: 1,
    name: 'Bordeaux by Night',
    category: 'cities',
    coverImage: 'https://images.unsplash.com/photo-1502602898657-3e91760cbb34?ixlib=rb-4.0.3&auto=format&fit=crop&w=1473&q=80',
    date: '2023-05-15',
    location: 'Bordeaux',
    photographer: 'Jean Dupont',
    photos: [
      { id: 1, url: 'https://images.unsplash.com/photo-1502602898657-3e91760cbb34?ixlib=rb-4.0.3&auto=format&fit=crop&w=1473&q=80', caption: 'Place de la Bourse' },
      { id: 2, url: 'https://images.unsplash.com/photo-1589983846997-04788035bc89?ixlib=rb-4.0.3&auto=format&fit=crop&w=1074&q=80', caption: 'Pont de pierre' },
      { id: 3, url: 'https://images.unsplash.com/photo-1558386620-ba3c0a160492?ixlib=rb-4.0.3&auto=format&fit=crop&w=1170&q=80', caption: 'Rue Sainte-Catherine' }
    ]
  },
  {
    id: 2,
    name: 'Dune du Pilat',
    category: 'landscapes',
    coverImage: 'https://images.unsplash.com/photo-1591375607635-2d8fd2b2d965?ixlib=rb-4.0.3&auto=format&fit=crop&w=1074&q=80',
    date: '2023-06-20',
    location: 'La Teste-de-Buch',
    photographer: 'Marie Lambert',
    photos: [
      { id: 1, url: 'https://images.unsplash.com/photo-1591375607635-2d8fd2b2d965?ixlib=rb-4.0.3&auto=format&fit=crop&w=1074&q=80', caption: 'Vue panoramique de la Dune du Pilat' },
      { id: 2, url: 'https://images.unsplash.com/photo-1596405758267-0a7bfba4d3c5?ixlib=rb-4.0.3&auto=format&fit=crop&w=1170&q=80', caption: 'Coucher de soleil sur la dune' },
      { id: 3, url: 'https://images.unsplash.com/photo-1589308454676-47f1a5b9c68b?ixlib=rb-4.0.3&auto=format&fit=crop&w=1170&q=80', caption: 'Paragliding au-dessus de la dune' }
    ]
  },
  // Ajoutez d'autres albums ici...
])

const selectedCategory = ref('all')
const selectedAlbum = ref(null)

const filteredAlbums = computed(() => {
  if (selectedCategory.value === 'all') {
    return albums.value
  } else {
    return albums.value.filter(album => album.category === selectedCategory.value)
  }
})

const selectCategory = (category) => {
  selectedCategory.value = category
}

const selectAlbum = (album) => {
  selectedAlbum.value = album
}

const closeAlbum = () => {
  selectedAlbum.value = null
}

const nextAlbum = () => {
  const currentIndex = filteredAlbums.value.findIndex(album => album.id === selectedAlbum.value.id)
  if (currentIndex < filteredAlbums.value.length - 1) {
    selectedAlbum.value = filteredAlbums.value[currentIndex + 1]
  }
}

const previousAlbum = () => {
  const currentIndex = filteredAlbums.value.findIndex(album => album.id === selectedAlbum.value.id)
  if (currentIndex > 0) {
    selectedAlbum.value = filteredAlbums.value[currentIndex - 1]
  }
}
</script>

<style scoped>
.gallery-page {
  font-family: 'Poppins', sans-serif;
  color: #333;
  line-height: 1.6;
}

.hero {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://images.unsplash.com/photo-1516939884455-1445c8652f83?ixlib=rb-4.0.3&auto=format&fit=crop&w=1470&q=80');
  background-size: cover;
  background-position: center;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}

.hero-content {
  max-width: 800px;
  padding: 0 20px;
}

.hero h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  animation: fadeInDown 1s ease-out;
}

.hero p {
  font-size: 1.5rem;
  animation: fadeInUp 1s ease-out 0.5s both;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.albums-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.album-card {
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.album-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.album-cover {
  position: relative;
  height: 300px;
  overflow: hidden;
}

.cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.album-card:hover .cover-image {
  transform: scale(1.1);
}

.album-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem;
  background: linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
  color: white;
  transform: translateY(100%);
  transition: transform 0.3s ease;
}

.album-card:hover .album-overlay {
  transform: translateY(0);
}

.album-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.album-location, .album-date {
  font-size: 0.9rem;
  opacity: 0.8;
  margin-bottom: 0.25rem;
}

.photo-count {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: rgba(255, 255, 255, 0.8);
  color: #333;
  padding: 0.25rem 0.5rem;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 600;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.5rem;
  }

  .hero p {
    font-size: 1.2rem;
  }

  .albums-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}
</style>