<template>
    <div class="category-tag-management-page">
      <h1>Gestion des Catégories et Tags</h1>
      
      <div class="management-sections">
        <div class="category-section">
          <h2>Catégories</h2>
          <div class="action-bar">
            <input v-model="newCategory" placeholder="Nouvelle catégorie" @keyup.enter="addCategory" />
            <button @click="addCategory" class="add-button">
              <i class="fas fa-plus"></i> Ajouter
            </button>
          </div>
          <ul class="item-list">
            <li v-for="category in categories" :key="category.id" class="item">
              <span v-if="!category.editing">{{ category.name }}</span>
              <input
                v-else
                v-model="category.editName"
                @keyup.enter="updateCategory(category)"
                @blur="updateCategory(category)"
              />
              <div class="item-actions">
                <button @click="startEditing(category)" class="edit-button">
                  <i class="fas fa-edit"></i>
                </button>
                <button @click="deleteCategory(category.id)" class="delete-button">
                  <i class="fas fa-trash"></i>
                </button>
              </div>
            </li>
          </ul>
        </div>
        
        <div class="tag-section">
          <h2>Tags</h2>
          <div class="action-bar">
            <input v-model="newTag" placeholder="Nouveau tag" @keyup.enter="addTag" />
            <button @click="addTag" class="add-button">
              <i class="fas fa-plus"></i> Ajouter
            </button>
          </div>
          <ul class="item-list">
            <li v-for="tag in tags" :key="tag.id" class="item">
              <span v-if="!tag.editing">{{ tag.name }}</span>
              <input
                v-else
                v-model="tag.editName"
                @keyup.enter="updateTag(tag)"
                @blur="updateTag(tag)"
              />
              <div class="item-actions">
                <button @click="startEditing(tag)" class="edit-button">
                  <i class="fas fa-edit"></i>
                </button>
                <button @click="deleteTag(tag.id)" class="delete-button">
                  <i class="fas fa-trash"></i>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue'
  
  export default {
    name: 'CategoryTagManagementPage',
    setup() {
      const categories = ref([
        { id: 1, name: 'Paysages', editing: false },
        { id: 2, name: 'Villes', editing: false },
        { id: 3, name: 'Événements', editing: false },
        { id: 4, name: 'Nature', editing: false },
        { id: 5, name: 'Architecture', editing: false },
      ])
      const tags = ref([
        { id: 1, name: 'Plage', editing: false },
        { id: 2, name: 'Montagne', editing: false },
        { id: 3, name: 'Coucher de soleil', editing: false },
        { id: 4, name: 'Historique', editing: false },
        { id: 5, name: 'Moderne', editing: false },
      ])
      const newCategory = ref('')
      const newTag = ref('')
  
      const addCategory = () => {
        if (newCategory.value.trim()) {
          categories.value.push({
            id: categories.value.length + 1,
            name: newCategory.value.trim(),
            editing: false
          })
          newCategory.value = ''
        }
      }
  
      const addTag = () => {
        if (newTag.value.trim()) {
          tags.value.push({
            id: tags.value.length + 1,
            name: newTag.value.trim(),
            editing: false
          })
          newTag.value = ''
        }
      }
  
      const startEditing = (item) => {
        item.editing = true
        item.editName = item.name
      }
  
      const updateCategory = (category) => {
        if (category.editName.trim()) {
          category.name = category.editName.trim()
        }
        category.editing = false
      }
  
      const updateTag = (tag) => {
        if (tag.editName.trim()) {
          tag.name = tag.editName.trim()
        }
        tag.editing = false
      }
  
      const deleteCategory = (id) => {
        categories.value = categories.value.filter(category => category.id !== id)
      }
  
      const deleteTag = (id) => {
        tags.value = tags.value.filter(tag => tag.id !== id)
      }
  
      return {
        categories,
        tags,
        newCategory,
        newTag,
        addCategory,
        addTag,
        startEditing,
        updateCategory,
        updateTag,
        deleteCategory,
        deleteTag
      }
    }
  }
  </script>
  
  <style scoped>
  .category-tag-management-page {
    padding: 2rem;
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: #2c3e50;
  }
  
  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #34495e;
  }
  
  .management-sections {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .category-section,
  .tag-section {
    background-color: white;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .action-bar {
    display: flex;
    margin-bottom: 1rem;
  }
  
  .action-bar input {
    flex-grow: 1;
    padding: 0.5rem;
    border: 1px solid #bdc3c7;
    border-radius: 4px 0 0 4px;
  }
  
  .add-button {
    background-color: #2ecc71;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .add-button:hover {
    background-color: #27ae60;
  }
  
  .item-list {
    list-style-type: none;
    padding: 0;
  }
  
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 0;
    border-bottom: 1px solid #ecf0f1;
  }
  
  .item:last-child {
    border-bottom: none;
  }
  
  .item input {
    flex-grow: 1;
    padding: 0.25rem;
    border: 1px solid #bdc3c7;
    border-radius: 4px;
  }
  
  .item-actions {
    display: flex;
    gap: 0.5rem;
  }
  
  .edit-button,
  .delete-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    transition: color 0.3s;
  }
  
  .edit-button {
    color: #3498db;
  }
  
  .edit-button:hover {
    color: #2980b9;
  }
  
  .delete-button {
    color: #e74c3c;
  }
  
  .delete-button:hover {
    color: #c0392b;
  }
  </style>