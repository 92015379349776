<template>
  <div class="album-list">
    <h2>Albums</h2>
    <div v-if="loading">Loading albums...</div>
    <div v-else-if="error">{{ error }}</div>
    <div v-else-if="albums.length === 0">No albums found.</div>
    <ul v-else>
      <li v-for="album in albums" :key="album.id" class="album-item">
        <h3>{{ album.title }}</h3>
        <p>Created by: {{ album.user.username }}</p>
        <p>Number of photos: {{ album.photoCount }}</p>
        <button @click="viewAlbum(album.id)">View Album</button>
      </li>
    </ul>
    <button @click="createAlbum" v-if="isAuthenticated">Create New Album</button>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'AlbumList',
  computed: {
    ...mapState(['albums', 'loading', 'error']),
    ...mapGetters(['isAuthenticated'])
  },
  methods: {
    ...mapActions(['fetchAlbums']),
    viewAlbum(albumId) {
      this.$router.push({ name: 'AlbumDetail', params: { id: albumId } })
    },
    createAlbum() {
      this.$router.push({ name: 'CreateAlbum' })
    }
  },
  mounted() {
    this.fetchAlbums()
  }
}
</script>

<style scoped>
.album-list {
  padding: 20px;
}

.album-item {
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
}

button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
}

button:hover {
  background-color: #45a049;
}
</style>