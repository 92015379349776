<template>
  <div class="statistics-page">
    <h1>Statistics</h1>
    <div v-if="loading">Loading statistics...</div>
    <div v-else-if="error">{{ error }}</div>
    <div v-else>
      <div class="chart-container">
        <canvas id="albumChart"></canvas>
      </div>
      <div class="chart-container">
        <canvas id="userChart"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js/auto'
import { mapState, mapActions } from 'vuex'

Chart.register(...registerables)

export default {
  name: 'StatisticsPage',
  computed: {
    ...mapState(['albums', 'users', 'loading', 'error'])
  },
  methods: {
    ...mapActions(['fetchAlbums', 'fetchUsers']),
    createAlbumChart() {
      const ctx = document.getElementById('albumChart')
      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.albums.map(album => album.title),
          datasets: [{
            label: 'Number of Photos',
            data: this.albums.map(album => album.photoCount),
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      })
    },
    createUserChart() {
      const ctx = document.getElementById('userChart')
      new Chart(ctx, {
        type: 'pie',
        data: {
          labels: this.users.map(user => user.username),
          datasets: [{
            label: 'Number of Albums',
            data: this.users.map(user => user.albumCount),
            backgroundColor: [
              'rgba(255, 99, 132, 0.6)',
              'rgba(54, 162, 235, 0.6)',
              'rgba(255, 206, 86, 0.6)',
              'rgba(75, 192, 192, 0.6)',
              'rgba(153, 102, 255, 0.6)'
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)'
            ],
            borderWidth: 1
          }]
        },
        options: {
          responsive: true
        }
      })
    }
  },
  async mounted() {
    await Promise.all([this.fetchAlbums(), this.fetchUsers()])
    this.createAlbumChart()
    this.createUserChart()
  }
}
</script>

<style scoped>
.statistics-page {
  padding: 20px;
}

.chart-container {
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
}
</style>