<template>
  <div class="album-viewer">
    <div class="viewer-content">
      <button class="close-btn" @click="$emit('close')">&times;</button>
      <div class="album-header" :style="{ backgroundImage: `url(${album.coverImage})` }">
        <h2>{{ album.name }}</h2>
      </div>
      <div class="album-details">
        <div class="detail-item">
          <i class="fas fa-calendar-alt"></i>
          <span>{{ album.date }}</span>
        </div>
        <div class="detail-item">
          <i class="fas fa-map-marker-alt"></i>
          <span>{{ album.location }}</span>
        </div>
        <div class="detail-item">
          <i class="fas fa-camera"></i>
          <span>{{ album.photographer }}</span>
        </div>
      </div>
      <div class="photo-gallery">
        <div v-for="photo in album.photos" :key="photo.id" class="photo-item">
          <img :src="photo.url" :alt="photo.caption" @click="openLightbox(photo)">
          <p class="photo-caption">{{ photo.caption }}</p>
        </div>
      </div>
      <div class="navigation-buttons">
        <button @click="$emit('previous-album')" class="nav-btn">&lt; Album précédent</button>
        <button @click="$emit('next-album')" class="nav-btn">Album suivant &gt;</button>
      </div>
    </div>
    <div v-if="lightboxPhoto" class="lightbox" @click="closeLightbox">
      <img :src="lightboxPhoto.url" :alt="lightboxPhoto.caption">
      <p class="lightbox-caption">{{ lightboxPhoto.caption }}</p>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

defineProps({
  album: {
    type: Object,
    required: true
  }
});

defineEmits(['close', 'previous-album', 'next-album']);

const lightboxPhoto = ref(null);

const openLightbox = (photo) => {
  lightboxPhoto.value = photo;
};

const closeLightbox = () => {
  lightboxPhoto.value = null;
};
</script>

<style scoped>
.album-viewer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.viewer-content {
  background-color: white;
  border-radius: 8px;
  max-width: 900px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  z-index: 10;
}

.album-header {
  height: 200px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
  padding: 1rem;
  position: relative;
}

.album-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.7));
}

.album-header h2 {
  color: white;
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  position: relative;
  z-index: 1;
}

.album-details {
  display: flex;
  justify-content: space-around;
  padding: 1rem;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
}

.detail-item {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #495057;
}

.detail-item i {
  margin-right: 0.5rem;
  color: #6c757d;
}

.photo-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  padding: 1rem;
}

.photo-item {
  text-align: center;
}

.photo-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.photo-item img:hover {
  transform: scale(1.05);
}

.photo-caption {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #6c757d;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: #f8f9fa;
  border-top: 1px solid #e9ecef;
}

.nav-btn {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nav-btn:hover {
  background-color: #0056b3;
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.lightbox img {
  max-width: 90%;
  max-height: 80vh;
  object-fit: contain;
}

.lightbox-caption {
  color: white;
  margin-top: 1rem;
  font-size: 1rem;
}
</style>