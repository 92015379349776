import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

// Import components
import HomePage from '@/views/HomePage.vue'
import AboutPage from '@/views/AboutPage.vue'
import LoginPage from '@/views/LoginPage.vue'
import AdminDashboard from '@/views/AdminDashboard.vue'
import AlbumList from '@/views/AlbumList.vue'
import AlbumDetailPage from '@/views/AlbumDetailPage.vue'
import UserManagementPage from '@/views/UserManagementPage.vue'
import MediaManagementPage from '@/views/MediaManagementPage.vue'
import CategoryTagManagementPage from '@/views/CategoryTagManagementPage.vue'
import CommentManagementPage from '@/views/CommentManagementPage.vue'
import StatisticsPage from '@/views/StatisticsPage.vue'
import SettingsPage from '@/views/SettingsPage.vue'
import ServicesPage from '@/views/ServicesPage.vue'
import GalleryPage from '@/views/GalleryPage.vue'
import ContactPage from '@/views/ContactPage.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
import TermsOfService from '@/views/TermsOfService.vue'
import NotFound from '@/views/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    meta: { title: 'Accueil - VIVRE LA ROCHELLE' }
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: AboutPage,
    meta: { title: 'À propos - VIVRE LA ROCHELLE' }
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
    meta: { title: 'Connexion - VIVRE LA ROCHELLE' }
  },
  {
    path: '/services',
    name: 'ServicesPage',
    component: ServicesPage,
    meta: { title: 'Nos Services - VIVRE LA ROCHELLE' }
  },
  {
    path: '/gallery',
    name: 'GalleryPage',
    component: GalleryPage,
    meta: { title: 'Galerie - VIVRE LA ROCHELLE' }
  },
  {
    path: '/contact',
    name: 'ContactPage',
    component: ContactPage,
    meta: { title: 'Contact - VIVRE LA ROCHELLE' }
  },
  {
    path: '/privacy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: { title: 'Politique de confidentialité - VIVRE LA ROCHELLE' }
  },
  {
    path: '/terms',
    name: 'TermsOfService',
    component: TermsOfService,
    meta: { title: 'Conditions d\'utilisation - VIVRE LA ROCHELLE' }
  },
  {
    path: '/admin',
    name: 'AdminDashboard',
    component: AdminDashboard,
    meta: { requiresAuth: true, requiresAdmin: true, title: 'Administration - VIVRE LA ROCHELLE' },
    children: [
      {
        path: 'albums',
        name: 'AlbumList',
        component: AlbumList,
        meta: { title: 'Liste des Albums - Administration' }
      },
      {
        path: 'albums/:id',
        name: 'AlbumDetailPage',
        component: AlbumDetailPage,
        props: true,
        meta: { title: 'Détails de l\'Album - Administration' }
      },
      {
        path: 'users',
        name: 'UserManagementPage',
        component: UserManagementPage,
        meta: { title: 'Gestion des Utilisateurs - Administration' }
      },
      {
        path: 'media',
        name: 'MediaManagementPage',
        component: MediaManagementPage,
        meta: { title: 'Gestion des Médias - Administration' }
      },
      {
        path: 'categories-tags',
        name: 'CategoryTagManagementPage',
        component: CategoryTagManagementPage,
        meta: { title: 'Gestion des Catégories et Tags - Administration' }
      },
      {
        path: 'comments',
        name: 'CommentManagementPage',
        component: CommentManagementPage,
        meta: { title: 'Gestion des Commentaires - Administration' }
      },
      {
        path: 'statistics',
        name: 'StatisticsPage',
        component: StatisticsPage,
        meta: { title: 'Statistiques - Administration' }
      },
      {
        path: 'settings',
        name: 'SettingsPage',
        component: SettingsPage,
        meta: { title: 'Paramètres - Administration' }
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: { title: 'Page non trouvée - VIVRE LA ROCHELLE' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, behavior: 'smooth' }
    }
  }
})

router.beforeEach(async (to, from, next) => {
  // Update page title
  document.title = to.meta.title || 'VIVRE LA ROCHELLE'

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      try {
        await store.dispatch('checkAuth')
      } catch (error) {
        console.error('Erreur lors de la vérification de l\'authentification:', error)
      }
    }
    
    if (!store.getters.isAuthenticated) {
      next({
        name: 'LoginPage',
        query: { redirect: to.fullPath }
      })
    } else if (to.matched.some(record => record.meta.requiresAdmin)) {
      if (store.getters.isAdmin) {
        next()
      } else {
        next({ name: 'HomePage' })
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router