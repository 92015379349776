<template>
  <footer class="site-footer">
    <div class="container">
      <div class="footer-logo">
        <img
          src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/LOGO%20LAROCHELLE%2002%20-qxHZg1QzQHmpggXb5e1kI2HN7cpkfT.JPG"
          alt="Vivre la Rochelle Photographie - Logo avec un appareil photo stylisé en jaune et la silhouette de La Rochelle"
          class="logo"
        />
      </div>
      <div class="footer-content">
        <div class="footer-section">
          <h3>À propos de VIVRE LA ROCHELLE</h3>
          <p>Nous sommes passionnés par la capture de moments uniques à travers notre objectif, mettant en valeur la beauté de La Rochelle et ses environs.</p>
        </div>
        <div class="footer-section">
          <h3>Liens rapides</h3>
          <ul>
            <li><router-link to="/">Accueil</router-link></li>
            <li><router-link to="/services">Services</router-link></li>
            <li><router-link to="/gallery">Galerie</router-link></li>
            <li><router-link to="/about">À propos</router-link></li>
            <li><router-link to="/contact">Contact</router-link></li>
          </ul>
        </div>
        <div class="footer-section">
          <h3>Contactez-nous</h3>
          <p>123 Rue du Vieux Port<br>17000 La Rochelle, France</p>
          <p>Téléphone: +33 5 46 00 00 00</p>
          <p>Email: contact@vivrelarochelle.com</p>
        </div>
        <div class="footer-section">
          <h3>Suivez-nous</h3>
          <div class="social-links">
            <a href="https://facebook.com/vivrelarochelle" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
              <i class="fab fa-facebook-f"></i>
            </a>
            <a href="https://instagram.com/vivrelarochelle" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
              <i class="fab fa-instagram"></i>
            </a>
            <a href="https://twitter.com/vivrelarochelle" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
              <i class="fab fa-twitter"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <p>&copy; 2024 VIVRE LA ROCHELLE. Tous droits réservés.</p>
        <nav class="legal-nav">
          <ul>
            <li><router-link to="/privacy">Politique de confidentialité</router-link></li>
            <li><router-link to="/terms">Conditions d'utilisation</router-link></li>
          </ul>
        </nav>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'SiteFooter'
}
</script>

<style scoped>
.site-footer {
  background-color: var(--color-background);
  color: var(--color-text);
  padding: 3rem 0 1rem;
  border-top: 2px solid var(--color-primary);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.footer-logo {
  text-align: center;
  margin-bottom: 2rem;
}

.footer-logo .logo {
  max-width: 300px;
  width: 100%;
  height: auto;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-section {
  flex: 1;
  margin-bottom: 2rem;
  min-width: 200px;
  padding-right: 1rem;
}

.footer-section h3 {
  font-family: var(--font-primary);
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--color-primary);
}

.footer-section p,
.footer-section li {
  font-family: var(--font-secondary);
  margin-bottom: 0.5rem;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section a {
  color: var(--color-text);
  text-decoration: none;
  transition: var(--transition);
}

.footer-section a:hover {
  color: var(--color-primary);
}

.social-links {
  display: flex;
  gap: 1rem;
}

.social-links a {
  color: var(--color-text);
  font-size: 1.5rem;
  transition: var(--transition);
}

.social-links a:hover {
  color: var(--color-primary);
}

.footer-bottom {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 1rem;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer-bottom p {
  font-size: 0.9rem;
}

.legal-nav ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  gap: 1rem;
}

.legal-nav a {
  color: var(--color-text);
  text-decoration: none;
  font-size: 0.9rem;
  transition: var(--transition);
}

.legal-nav a:hover {
  color: var(--color-primary);
}

@media (max-width: 768px) {
  .footer-logo .logo {
    max-width: 250px;
  }

  .footer-content {
    flex-direction: column;
  }

  .footer-section {
    width: 100%;
    padding-right: 0;
  }

  .footer-bottom {
    flex-direction: column;
    text-align: center;
    gap: 1rem;
  }

  .legal-nav ul {
    justify-content: center;
  }
}
</style>