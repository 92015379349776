<template>
  <div class="login-page">
    <div class="hero">
      <div class="hero-content">
        <h1>VIVRE LA ROCHELLE</h1>
        <p>Découvrez et explorez la beauté de La Rochelle</p>
      </div>
      <div class="hero-background">
        <div class="shape shape-1"></div>
        <div class="shape shape-2"></div>
        <div class="shape shape-3"></div>
      </div>
    </div>
    <div class="login-container">
      <h2>Connexion</h2>
      <form @submit.prevent="handleLogin" class="login-form">
        <div class="form-group">
          <label for="email">Email</label>
          <input 
            type="email" 
            id="email" 
            v-model="email" 
            required
            autocomplete="username"
            placeholder="Entrez votre email"
          >
        </div>
        <div class="form-group">
          <label for="password">Mot de passe</label>
          <input 
            type="password" 
            id="password" 
            v-model="password" 
            required
            autocomplete="current-password"
            placeholder="Entrez votre mot de passe"
          >
        </div>
        <button type="submit" :disabled="isLoading" class="login-button">
          <span v-if="!isLoading">Se connecter</span>
          <div v-else class="loader"></div>
        </button>
      </form>
      <p v-if="error" class="error-message">{{ error }}</p>
      <p v-if="isLoading && !error" class="loading-message">Connexion en cours...</p>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'LoginPage',
  setup() {
    const store = useStore()
    const router = useRouter()
    const email = ref('')
    const password = ref('')
    const error = ref('')
    const isLoading = ref(false)

    const handleLogin = async () => {
      isLoading.value = true
      error.value = ''
      try {
        await store.dispatch('login', { email: email.value, password: password.value })
        await new Promise(resolve => setTimeout(resolve, 2000))
        router.push('/admin')
      } catch (err) {
        error.value = "Échec de la connexion. Veuillez vérifier vos identifiants."
      } finally {
        isLoading.value = false
      }
    }

    return {
      email,
      password,
      error,
      isLoading,
      handleLogin
    }
  }
}
</script>

<style scoped>
.login-page {
  display: flex;
  min-height: 100vh;
  background-color: #f4f7fa;
}

.hero {
  flex: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #3498db, #8e44ad);
}

.hero-content {
  position: relative;
  z-index: 2;
  text-align: center;
  color: white;
  padding: 2rem;
}

.hero h1 {
  font-size: 3.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  animation: fadeInUp 1s ease-out;
}

.hero p {
  font-size: 1.2rem;
  max-width: 400px;
  margin: 0 auto;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  animation: fadeInUp 1s ease-out 0.3s;
  opacity: 0;
  animation-fill-mode: forwards;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.shape {
  position: absolute;
  opacity: 0.1;
  animation: float 6s ease-in-out infinite;
}

.shape-1 {
  top: 20%;
  left: 10%;
  width: 100px;
  height: 100px;
  background-color: #ffffff;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  animation-delay: 0s;
}

.shape-2 {
  top: 60%;
  right: 20%;
  width: 150px;
  height: 150px;
  background-color: #ffffff;
  border-radius: 50%;
  animation-delay: 2s;
}

.shape-3 {
  bottom: 10%;
  left: 30%;
  width: 80px;
  height: 80px;
  background-color: #ffffff;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  animation-delay: 4s;
}

.login-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: white;
  box-shadow: -10px 0 20px rgba(0, 0, 0, 0.1);
}

h2 {
  color: #333;
  margin-bottom: 2rem;
  font-size: 2rem;
}

.login-form {
  width: 100%;
  max-width: 400px;
}

.form-group {
  margin-bottom: 1.5rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: #555;
  font-weight: bold;
}

input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

input:focus, input:hover {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.login-button {
  width: 100%;
  background-color: #3498db;
  color: white;
  border: none;
  padding: 0.75rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.login-button:hover:not(:disabled) {
  background-color: #2980b9;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.login-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.error-message {
  color: #e74c3c;
  text-align: center;
  margin-top: 1rem;
}

.loading-message {
  color: #3498db;
  text-align: center;
  margin-top: 1rem;
}

.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .login-page {
    flex-direction: column;
  }

  .hero, .login-container {
    flex: none;
  }

  .hero {
    padding: 3rem 1rem;
  }

  .hero h1 {
    font-size: 2.5rem;
  }

  .hero p {
    font-size: 1rem;
  }

  .login-container {
    padding: 2rem 1rem;
  }

  h2 {
    font-size: 1.8rem;
  }
}
</style>