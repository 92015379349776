import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
  state: {
    albums: [],
    users: [],
    currentUser: null,
    loading: false,
    error: null
  },
  mutations: {
    setAlbums(state, albums) {
      state.albums = albums
    },
    setUsers(state, users) {
      state.users = users
    },
    setCurrentUser(state, user) {
      state.currentUser = user
    },
    setLoading(state, loading) {
      state.loading = loading
    },
    setError(state, error) {
      state.error = error
    }
  },
  actions: {
    async fetchAlbums({ commit }) {
      commit('setLoading', true)
      try {
        const response = await axios.get('/api/albums')
        commit('setAlbums', response.data)
        commit('setError', null)
      } catch (error) {
        console.error('Error fetching albums:', error)
        commit('setError', 'Failed to fetch albums')
      } finally {
        commit('setLoading', false)
      }
    },
    async fetchUsers({ commit }) {
      commit('setLoading', true)
      try {
        const response = await axios.get('/api/users')
        commit('setUsers', response.data)
        commit('setError', null)
      } catch (error) {
        console.error('Error fetching users:', error)
        commit('setError', 'Failed to fetch users')
      } finally {
        commit('setLoading', false)
      }
    },
    async login({ commit }, credentials) {
      commit('setLoading', true)
      try {
        const response = await axios.post('/api/login', credentials)
        commit('setCurrentUser', response.data)
        commit('setError', null)
      } catch (error) {
        console.error('Error logging in:', error)
        commit('setError', 'Failed to log in')
      } finally {
        commit('setLoading', false)
      }
    },
    async logout({ commit }) {
      commit('setLoading', true)
      try {
        await axios.post('/api/logout')
        commit('setCurrentUser', null)
        commit('setError', null)
      } catch (error) {
        console.error('Error logging out:', error)
        commit('setError', 'Failed to log out')
      } finally {
        commit('setLoading', false)
      }
    }
  },
  getters: {
    isAuthenticated: state => !!state.currentUser,
    currentUser: state => state.currentUser,
    albums: state => state.albums,
    users: state => state.users,
    isLoading: state => state.loading,
    error: state => state.error
  }
})