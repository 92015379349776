<template>
  <div class="admin-dashboard">
    <h1>Admin Dashboard</h1>
    <nav class="admin-nav">
      <router-link to="/admin/albums">Albums</router-link>
      <router-link to="/admin/users">Users</router-link>
      <router-link to="/admin/media">Media</router-link>
      <router-link to="/admin/categories-tags">Categories & Tags</router-link>
      <router-link to="/admin/comments">Comments</router-link>
      <router-link to="/admin/statistics">Statistics</router-link>
      <router-link to="/admin/settings">Settings</router-link>
    </nav>
    <div class="dashboard-content">
      <h2>Welcome, {{ currentUser.name }}</h2>
      <div class="quick-stats">
        <div class="stat-card">
          <h3>Total Albums</h3>
          <p>{{ stats.totalAlbums }}</p>
        </div>
        <div class="stat-card">
          <h3>Total Users</h3>
          <p>{{ stats.totalUsers }}</p>
        </div>
        <div class="stat-card">
          <h3>Total Photos</h3>
          <p>{{ stats.totalPhotos }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'AdminDashboard',
  setup() {
    const store = useStore()
    const stats = ref({
      totalAlbums: 0,
      totalUsers: 0,
      totalPhotos: 0
    })

    const currentUser = computed(() => store.getters.currentUser)

    onMounted(async () => {
      try {
        const response = await store.dispatch('fetchStatistics')
        stats.value = response
      } catch (error) {
        console.error('Error fetching statistics:', error)
      }
    })

    return {
      currentUser,
      stats
    }
  }
}
</script>

<style scoped>
.admin-dashboard {
  padding: 20px;
}

.admin-nav {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}

.admin-nav a {
  text-decoration: none;
  color: #42b983;
  font-weight: bold;
}

.admin-nav a:hover {
  text-decoration: underline;
}

.dashboard-content {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
}

.quick-stats {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.stat-card {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1;
  margin: 0 10px;
  text-align: center;
}

.stat-card h3 {
  margin-bottom: 10px;
  color: #42b983;
}

.stat-card p {
  font-size: 24px;
  font-weight: bold;
}
</style>