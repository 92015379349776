<template>
  <div class="home-page">
    <section class="hero">
      <div class="hero-slider">
        <div class="hero-slide" v-for="(slide, index) in heroSlides" :key="index">
          <img :src="slide.image" :alt="slide.alt" class="hero-image" />
          <div class="hero-overlay"></div>
        </div>
      </div>
      <div class="hero-content">
        <h1 class="hero-title">VIVRE LA ROCHELLE</h1>
        <p class="hero-subtitle">Capturez l'âme de notre ville</p>
        <div class="hero-cta">
          <a href="#portfolio" class="btn btn-primary">Voir le portfolio</a>
          <button @click="showBookingForm = true" class="btn btn-secondary">Réserver une séance</button>
        </div>
      </div>
    </section>

    <ServicesSection />
    <WhyChooseUs />

    <div v-if="showBookingForm" class="booking-form-overlay">
      <div class="booking-form">
        <h2>Réserver une séance</h2>
        <form @submit.prevent="submitBooking">
          <div class="form-group">
            <label for="name">Nom complet</label>
            <input type="text" id="name" v-model="booking.name" required>
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" id="email" v-model="booking.email" required>
          </div>
          <div class="form-group">
            <label for="date">Date souhaitée</label>
            <input type="date" id="date" v-model="booking.date" required>
          </div>
          <div class="form-group">
            <label for="type">Type de séance</label>
            <select id="type" v-model="booking.type" required>
              <option value="">Sélectionnez un type</option>
              <option value="portrait">Portrait</option>
              <option value="event">Événement</option>
              <option value="landscape">Paysage</option>
            </select>
          </div>
          <div class="form-group">
            <label for="message">Message (optionnel)</label>
            <textarea id="message" v-model="booking.message"></textarea>
          </div>
          <div class="form-actions">
            <button type="submit" class="btn btn-primary">Envoyer</button>
            <button type="button" @click="showBookingForm = false" class="btn btn-secondary">Annuler</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import ServicesSection from '@/components/ServicesSection.vue'
import WhyChooseUs from '@/components/WhyChooseUs.vue'

const heroSlides = ref([
  { image: 'https://images.unsplash.com/photo-1564594874687-ae5a1d70a1b0?ixlib=rb-4.0.3&auto=format&fit=crop&w=1920&q=80', alt: 'Port de La Rochelle au coucher du soleil' },
  { image: 'https://images.unsplash.com/photo-1589570731441-a2a4c6e9e4b7?ixlib=rb-4.0.3&auto=format&fit=crop&w=1920&q=80', alt: 'Vue aérienne de La Rochelle' },
  { image: 'https://images.unsplash.com/photo-1601652589234-c282487ec97e?ixlib=rb-4.0.3&auto=format&fit=crop&w=1920&q=80', alt: 'Rue pittoresque de La Rochelle' }
])

const showBookingForm = ref(false)
const booking = ref({
  name: '',
  email: '',
  date: '',
  type: '',
  message: ''
})

let slideInterval

const startSlideshow = () => {
  slideInterval = setInterval(() => {
    const firstSlide = heroSlides.value.shift()
    heroSlides.value.push(firstSlide)
  }, 5000)
}

const submitBooking = () => {
  // Ici, vous pouvez ajouter la logique pour envoyer les données du formulaire
  console.log('Réservation soumise:', booking.value)
  alert('Merci pour votre réservation ! Nous vous contacterons bientôt.')
  showBookingForm.value = false
  // Réinitialiser le formulaire
  booking.value = { name: '', email: '', date: '', type: '', message: '' }
}

onMounted(() => {
  startSlideshow()
})

onUnmounted(() => {
  clearInterval(slideInterval)
})
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Roboto:wght@300;400;700&display=swap');

.home-page {
  display: flex;
  flex-direction: column;
}

.hero {
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.hero-slider {
  height: 100%;
  width: 300%;
  display: flex;
  transition: transform 1s ease;
}

.hero-slide {
  width: 33.333%;
  height: 100%;
  position: relative;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #ffffff;
  z-index: 10;
  width: 90%;
  max-width: 800px;
}

.hero-title {
  font-family: 'Playfair Display', serif;
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.hero-subtitle {
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 2rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.hero-cta {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.btn {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
  border-radius: 50px;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  border: none;
}

.btn-primary {
  background-color: #FFD700;
  color: #000000;
}

.btn-primary:hover {
  background-color: #FFC800;
  transform: translateY(-3px);
}

.btn-secondary {
  background-color: transparent;
  color: #ffffff;
  border: 2px solid #ffffff;
}

.btn-secondary:hover {
  background-color: #ffffff;
  color: #000000;
  transform: translateY(-3px);
}

.booking-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.booking-form {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
}

.booking-form h2 {
  font-family: 'Playfair Display', serif;
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #333;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-family: 'Roboto', sans-serif;
  color: #333;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.5rem;
  font-family: 'Roboto', sans-serif;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }

  .hero-subtitle {
    font-size: 1.2rem;
  }

  .hero-cta {
    flex-direction: column;
  }

  .btn {
    width: 100%;
    margin-bottom: 1rem;
  }
}

@keyframes slideAnimation {
  0% {
    transform: translateX(0);
  }
  33.33% {
    transform: translateX(-33.333%);
  }
  66.66% {
    transform: translateX(-66.666%);
  }
  100% {
    transform: translateX(0);
  }
}

.hero-slider {
  animation: slideAnimation 15s infinite;
}

.hero-slider:hover {
  animation-play-state: paused;
}
</style>