<template>
  <div class="privacy-policy">
    <h1>Politique de Confidentialité</h1>
    <p class="last-updated">Dernière mise à jour : {{ lastUpdated }}</p>

    <section>
      <h2>1. Introduction</h2>
      <p>Bienvenue sur Vivre La Rochelle. Nous nous engageons à protéger votre vie privée et à traiter vos données personnelles avec le plus grand soin. Cette politique de confidentialité explique comment nous collectons, utilisons et protégeons vos informations personnelles.</p>
    </section>

    <section>
      <h2>2. Collecte des informations</h2>
      <p>Nous collectons les informations suivantes :</p>
      <ul>
        <li>Nom et prénom</li>
        <li>Adresse e-mail</li>
        <li>Numéro de téléphone</li>
        <li>Informations de réservation</li>
      </ul>
    </section>

    <section>
      <h2>3. Utilisation des informations</h2>
      <p>Nous utilisons vos informations pour :</p>
      <ul>
        <li>Gérer vos réservations</li>
        <li>Vous contacter concernant vos séances photo</li>
        <li>Améliorer nos services</li>
        <li>Envoyer des newsletters (avec votre consentement)</li>
      </ul>
    </section>

    <section>
      <h2>4. Protection des informations</h2>
      <p>Nous mettons en place des mesures de sécurité pour protéger vos informations contre l'accès non autorisé, la modification, la divulgation ou la destruction.</p>
    </section>

    <section>
      <h2>5. Vos droits</h2>
      <p>Vous avez le droit de :</p>
      <ul>
        <li>Accéder à vos données personnelles</li>
        <li>Rectifier vos données</li>
        <li>Demander la suppression de vos données</li>
        <li>Vous opposer au traitement de vos données</li>
      </ul>
    </section>

    <section>
      <h2>6. Cookies</h2>
      <p>Nous utilisons des cookies pour améliorer votre expérience sur notre site. Vous pouvez configurer votre navigateur pour refuser tous les cookies ou pour indiquer quand un cookie est envoyé.</p>
    </section>

    <section>
      <h2>7. Modifications de la politique de confidentialité</h2>
      <p>Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Toute modification sera publiée sur cette page.</p>
    </section>

    <section>
      <h2>8. Nous contacter</h2>
      <p>Si vous avez des questions concernant cette politique de confidentialité, veuillez nous contacter à :</p>
      <p>Email : privacy@vivrelarochelle.com</p>
      <p>Adresse : 123 Rue de la Photographie, 17000 La Rochelle, France</p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  data() {
    return {
      lastUpdated: '1 juin 2023'
    }
  }
}
</script>

<style scoped>
.privacy-policy {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
  color: #333;
}

h1 {
  font-family: 'Playfair Display', serif;
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 1rem;
}

h2 {
  font-family: 'Playfair Display', serif;
  font-size: 1.8rem;
  color: #34495e;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.last-updated {
  font-style: italic;
  color: #7f8c8d;
  margin-bottom: 2rem;
}

section {
  margin-bottom: 2rem;
}

ul {
  padding-left: 1.5rem;
}

@media (max-width: 768px) {
  .privacy-policy {
    padding: 1rem;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }
}
</style>