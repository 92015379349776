<template>
  <section id="why-choose-us" class="why-choose-us">
    <div class="container">
      <h2 class="section-title">Pourquoi nous choisir</h2>
      <div class="reasons-grid">
        <div v-for="reason in reasons" :key="reason.title" class="reason-card">
          <div class="reason-icon">
            <img :src="reason.icon" :alt="reason.title">
          </div>
          <h3>{{ reason.title }}</h3>
          <p>{{ reason.description }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue'

const reasons = ref([
  {
    title: "Professionnalisme",
    description: "Avec nous, pas de panique ! On sait capturer l'instant parfait, même si vous êtes en train de refaire votre rouge à lèvres ou de vérifier si vos cheveux sont bien placés.",
    icon: "https://api.iconify.design/mdi:camera-iris.svg?color=%23e74c3c"
  },
  {
    title: "Approche personnalisée",
    description: "Que vous soyez timide devant l'objectif ou une star née, on s'adapte à vous. Et on vous promet que les paysages magnifiques de la Nouvelle-Aquitaine seront le cadre idéal pour chaque clic.",
    icon: "https://api.iconify.design/mdi:palette-outline.svg?color=%23e74c3c"
  },
  {
    title: "Mise en valeur de la région",
    description: "Plutôt que d'aller chercher des décors de cinéma, pourquoi ne pas utiliser ceux qui sont juste à côté de chez vous ? On connaît tous les meilleurs spots, de la dune du Pilat aux ruelles cachées des vieux villages landais.",
    icon: "https://api.iconify.design/mdi:map-marker-radius-outline.svg?color=%23e74c3c"
  }
])
</script>

<style scoped>
.why-choose-us {
  padding: 6rem 0;
  background-color: #f8f9fa;
  position: relative;
  overflow: hidden;
}

.why-choose-us::before {
  content: '';
  position: absolute;
  top: -50px;
  left: -50px;
  width: 200px;
  height: 200px;
  background-color: rgba(231, 76, 60, 0.1);
  border-radius: 50%;
  z-index: 0;
}

.why-choose-us::after {
  content: '';
  position: absolute;
  bottom: -50px;
  right: -50px;
  width: 150px;
  height: 150px;
  background-color: rgba(231, 76, 60, 0.1);
  border-radius: 50%;
  z-index: 0;
}

.container {
  position: relative;
  z-index: 1;
}

.section-title {
  text-align: center;
  margin-bottom: 4rem;
  font-size: 2.5rem;
  color: #2c3e50;
  position: relative;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 50%;
  height: 3px;
  background-color: #e74c3c;
  transform: translateX(50%);
}

.reasons-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3rem;
}

.reason-card {
  background-color: white;
  border-radius: 15px;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.reason-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background-color: #e74c3c;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.reason-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.reason-card:hover::before {
  transform: scaleX(1);
}

.reason-icon {
  width: 80px;
  height: 80px;
  margin: 0 auto 1.5rem;
  background-color: rgba(231, 76, 60, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}

.reason-card:hover .reason-icon {
  transform: scale(1.1);
}

.reason-icon img {
  width: 40px;
  height: 40px;
}

.reason-card h3 {
  font-size: 1.5rem;
  color: #2c3e50;
  margin-bottom: 1rem;
}

.reason-card p {
  color: #34495e;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .reasons-grid {
    grid-template-columns: 1fr;
  }
}
</style>