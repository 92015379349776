<template>
    <div class="settings-page">
      <h1>Paramètres</h1>
      <div class="settings-container">
        <div class="setting-section">
          <h2>Paramètres généraux</h2>
          <div class="form-group">
            <label for="siteName">Nom du site</label>
            <input type="text" id="siteName" v-model="settings.siteName">
          </div>
          <div class="form-group">
            <label for="siteDescription">Description du site</label>
            <textarea id="siteDescription" v-model="settings.siteDescription"></textarea>
          </div>
        </div>
        
        <div class="setting-section">
          <h2>Paramètres de l'album</h2>
          <div class="form-group">
            <label for="albumsPerPage">Albums par page</label>
            <input type="number" id="albumsPerPage" v-model="settings.albumsPerPage">
          </div>
          <div class="form-group">
            <label for="defaultAlbumPrivacy">Confidentialité par défaut des albums</label>
            <select id="defaultAlbumPrivacy" v-model="settings.defaultAlbumPrivacy">
              <option value="public">Public</option>
              <option value="private">Privé</option>
            </select>
          </div>
        </div>
        
        <div class="setting-section">
          <h2>Paramètres utilisateur</h2>
          <div class="form-group">
            <label for="allowRegistration">Autoriser l'inscription des utilisateurs</label>
            <input type="checkbox" id="allowRegistration" v-model="settings.allowRegistration">
          </div>
          <div class="form-group">
            <label for="defaultUserRole">Rôle par défaut des nouveaux utilisateurs</label>
            <select id="defaultUserRole" v-model="settings.defaultUserRole">
              <option value="user">Utilisateur</option>
              <option value="contributor">Contributeur</option>
              <option value="editor">Éditeur</option>
            </select>
          </div>
        </div>
      </div>
      <div class="button-group">
        <button @click="saveSettings" class="save-button">Enregistrer les paramètres</button>
        <button @click="resetSettings" class="reset-button">Réinitialiser</button>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue'
  
  export default {
    name: 'SettingsPage',
    setup() {
      const settings = ref({
        siteName: 'NEO AQUITAINE',
        siteDescription: 'Découvrez la beauté de la Nouvelle-Aquitaine à travers nos albums photo',
        albumsPerPage: 12,
        defaultAlbumPrivacy: 'public',
        allowRegistration: true,
        defaultUserRole: 'user'
      })
  
      const saveSettings = () => {
        // Ici, vous implémenteriez la logique pour sauvegarder les paramètres
        // Par exemple, envoyer une requête API pour mettre à jour les paramètres dans la base de données
        console.log('Paramètres sauvegardés:', settings.value)
        alert('Paramètres sauvegardés avec succès!')
      }
  
      const resetSettings = () => {
        // Réinitialiser les paramètres à leurs valeurs par défaut
        settings.value = {
          siteName: 'NEO AQUITAINE',
          siteDescription: 'Découvrez la beauté de la Nouvelle-Aquitaine à travers nos albums photo',
          albumsPerPage: 12,
          defaultAlbumPrivacy: 'public',
          allowRegistration: true,
          defaultUserRole: 'user'
        }
      }
  
      return {
        settings,
        saveSettings,
        resetSettings
      }
    }
  }
  </script>
  
  <style scoped>
  .settings-page {
    padding: 2rem;
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: #2c3e50;
  }
  
  .settings-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .setting-section {
    background-color: white;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #34495e;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  label {
    display: block;
    margin-bottom: 0.5rem;
    color: #7f8c8d;
  }
  
  input[type="text"],
  input[type="number"],
  textarea,
  select {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #bdc3c7;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  input[type="checkbox"] {
    margin-right: 0.5rem;
  }
  
  .button-group {
    margin-top: 2rem;
    display: flex;
    gap: 1rem;
  }
  
  .save-button,
  .reset-button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .save-button {
    background-color: #2ecc71;
    color: white;
  }
  
  .save-button:hover {
    background-color: #27ae60;
  }
  
  .reset-button {
    background-color: #e74c3c;
    color: white;
  }
  
  .reset-button:hover {
    background-color: #c0392b;
  }
  </style>