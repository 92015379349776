<template>
  <div class="contact-page">
    <div class="container">
      <h1 class="page-title">Contactez-nous</h1>
      <p class="page-description">Nous sommes là pour répondre à toutes vos questions et demandes</p>

      <div class="contact-content">
        <form @submit.prevent="submitForm" class="contact-form">
          <div class="form-group">
            <label for="name">Nom complet</label>
            <input type="text" id="name" v-model="form.name" required>
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" id="email" v-model="form.email" required>
          </div>
          <div class="form-group">
            <label for="phone">Téléphone</label>
            <input type="tel" id="phone" v-model="form.phone">
          </div>
          <div class="form-group">
            <label for="subject">Sujet</label>
            <select id="subject" v-model="form.subject" required>
              <option value="">Sélectionnez un sujet</option>
              <option value="general">Renseignement général</option>
              <option value="booking">Réservation de séance</option>
              <option value="quote">Demande de devis</option>
              <option value="feedback">Retour d'expérience</option>
            </select>
          </div>
          <div class="form-group">
            <label for="message">Message</label>
            <textarea id="message" v-model="form.message" required></textarea>
          </div>
          <button type="submit" class="submit-button">Envoyer</button>
        </form>

        <div class="contact-info">
          <h2>Informations de contact</h2>
          <div class="info-item">
            <i class="fas fa-map-marker-alt"></i>
            <p>123 Rue de la Photographie, 33000 Bordeaux</p>
          </div>
          <div class="info-item">
            <i class="fas fa-phone"></i>
            <p>+33 5 55 55 55 55</p>
          </div>
          <div class="info-item">
            <i class="fas fa-envelope"></i>
            <p>contact@neoaquitaine.com</p>
          </div>
          <div class="info-item">
            <i class="fas fa-clock"></i>
            <p>
              Lundi - Vendredi : 9h00 - 18h00<br>
              Samedi : 10h00 - 16h00<br>
              Dimanche : Fermé
            </p>
          </div>
          <div class="social-media">
            <a href="#" class="social-icon"><i class="fab fa-facebook-f"></i></a>
            <a href="#" class="social-icon"><i class="fab fa-instagram"></i></a>
            <a href="#" class="social-icon"><i class="fab fa-twitter"></i></a>
            <a href="#" class="social-icon"><i class="fab fa-linkedin-in"></i></a>
          </div>
        </div>
      </div>

      <div class="map-container">
        <h2>Nous trouver</h2>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2828.951085952438!2d-0.5774603846036392!3d44.84132397909855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd5527e8f751ca81%3A0x796386037b397a89!2sBordeaux%2C%20France!5e0!3m2!1sen!2sus!4v1620058882297!5m2!1sen!2sus"
          width="100%"
          height="450"
          style="border:0;"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'ContactPage',
  setup() {
    const form = ref({
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: ''
    });

    const submitForm = () => {
      console.log('Formulaire soumis:', form.value);
      alert('Merci pour votre message. Nous vous contacterons bientôt !');
      form.value = { name: '', email: '', phone: '', subject: '', message: '' };
    };

    return {
      form,
      submitForm
    };
  }
};
</script>

<style scoped>
.contact-page {
  padding: 4rem 0;
}

.page-title {
  font-size: 2.5rem;
  color: var(--color-primary);
  text-align: center;
  margin-bottom: 1rem;
}

.page-description {
  text-align: center;
  color: var(--color-secondary);
  margin-bottom: 3rem;
}

.contact-content {
  display: flex;
  gap: 4rem;
  margin-bottom: 4rem;
}

.contact-form {
  flex: 1;
  background-color: white;
  padding: 2rem;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--color-secondary);
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e0e0e0;
  border-radius: var(--border-radius);
  font-size: 1rem;
}

.form-group textarea {
  height: 150px;
  resize: vertical;
}

.submit-button {
  width: 100%;
  padding: 1rem;
  background-color: var(--color-accent);
  color: white;
  border: none;
  border-radius: var(--border-radius);
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #c0392b;
}

.contact-info {
  flex: 1;
}

.contact-info h2 {
  font-size: 1.5rem;
  color: var(--color-primary);
  margin-bottom: 1.5rem;
}

.info-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.info-item i {
  color: var(--color-accent);
  font-size: 1.2rem;
  margin-right: 1rem;
  margin-top: 0.2rem;
}

.social-media {
  margin-top: 2rem;
}

.social-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: var(--color-primary);
  color: white;
  border-radius: 50%;
  margin-right: 1rem;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;
}

.social-icon:hover {
  background-color: var(--color-accent);
}

.map-container {
  margin-top: 4rem;
}

.map-container h2 {
  font-size: 1.5rem;
  color: var(--color-primary);
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .contact-content {
    flex-direction: column;
  }

  .contact-form,
  .contact-info {
    width: 100%;
  }
}
</style>