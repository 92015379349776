<template>
  <div class="user-management">
    <h1>User Management</h1>
    <div v-if="loading" class="loading">Loading users...</div>
    <div v-else-if="error" class="error">{{ error }}</div>
    <div v-else>
      <table class="user-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.id">
            <td>{{ user.id }}</td>
            <td>{{ user.name }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.role }}</td>
            <td>
              <button @click="editUser(user)" class="edit-btn">Edit</button>
              <button @click="deleteUser(user.id)" class="delete-btn">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
      <button @click="showAddUserModal" class="add-user-btn">Add New User</button>
    </div>

    <!-- Add/Edit User Modal -->
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <h2>{{ editingUser ? 'Edit User' : 'Add New User' }}</h2>
        <form @submit.prevent="saveUser">
          <div class="form-group">
            <label for="name">Name:</label>
            <input v-model="currentUser.name" id="name" required>
          </div>
          <div class="form-group">
            <label for="email">Email:</label>
            <input v-model="currentUser.email" id="email" type="email" required>
          </div>
          <div class="form-group">
            <label for="role">Role:</label>
            <select v-model="currentUser.role" id="role">
              <option value="user">User</option>
              <option value="admin">Admin</option>
            </select>
          </div>
          <div class="form-group" v-if="!editingUser">
            <label for="password">Password:</label>
            <input v-model="currentUser.password" id="password" type="password" required>
          </div>
          <div class="modal-actions">
            <button type="submit" class="save-btn">Save</button>
            <button @click="closeModal" class="cancel-btn">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'UserManagement',
  setup() {
    const store = useStore()
    const users = ref([])
    const loading = ref(true)
    const error = ref(null)
    const showModal = ref(false)
    const currentUser = ref({})
    const editingUser = ref(false)

    const fetchUsers = async () => {
      try {
        const response = await store.dispatch('fetchUsers')
        users.value = response
        loading.value = false
      } catch (err) {
        error.value = 'Failed to load users. Please try again.'
        loading.value = false
      }
    }

    const showAddUserModal = () => {
      currentUser.value = { role: 'user' }
      editingUser.value = false
      showModal.value = true
    }

    const editUser = (user) => {
      currentUser.value = { ...user }
      editingUser.value = true
      showModal.value = true
    }

    const closeModal = () => {
      showModal.value = false
      currentUser.value = {}
    }

    const saveUser = async () => {
      try {
        if (editingUser.value) {
          await store.dispatch('updateUser', currentUser.value)
        } else {
          await store.dispatch('createUser', currentUser.value)
        }
        await fetchUsers()
        closeModal()
      } catch (err) {
        error.value = 'Failed to save user. Please try again.'
      }
    }

    const deleteUser = async (userId) => {
      if (confirm('Are you sure you want to delete this user?')) {
        try {
          await store.dispatch('deleteUser', userId)
          await fetchUsers()
        } catch (err) {
          error.value = 'Failed to delete user. Please try again.'
        }
      }
    }

    onMounted(fetchUsers)

    return {
      users,
      loading,
      error,
      showModal,
      currentUser,
      editingUser,
      showAddUserModal,
      editUser,
      closeModal,
      saveUser,
      deleteUser
    }
  }
}
</script>

<style scoped>
.user-management {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.user-table th, .user-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.user-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.edit-btn, .delete-btn, .add-user-btn {
  padding: 8px 12px;
  margin-right: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.edit-btn {
  background-color: #4CAF50;
  color: white;
}

.delete-btn {
  background-color: #f44336;
  color: white;
}

.add-user-btn {
  background-color: #008CBA;
  color: white;
  font-size: 1em;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input, .form-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.modal-actions {
  text-align: right;
  margin-top: 20px;
}

.save-btn, .cancel-btn {
  padding: 10px 15px;
  margin-left: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.save-btn {
  background-color: #4CAF50;
  color: white;
}

.cancel-btn {
  background-color: #f44336;
  color: white;
}

.loading, .error {
  text-align: center;
  margin-top: 20px;
  font-size: 1.2em;
}

.error {
  color: #f44336;
}
</style>