<template>
    <section id="services" class="services-section">
      <div class="container">
        <h2>Nos Services</h2>
        <div class="services-grid">
          <div v-for="service in services" :key="service.title" class="service-card">
            <div class="service-image" :style="{ backgroundImage: `url(${service.image})` }"></div>
            <div class="service-content">
              <h3>{{ service.title }}</h3>
              <p>{{ service.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script setup>
  import { ref } from 'vue'
  
  const services = ref([
    {
      title: "Photographie d'événements",
      description: "Nous capturons vos moments les plus précieux lors de mariages, anniversaires ou séminaires d'entreprise.",
      image: "https://images.unsplash.com/photo-1511795409834-ef04bbd61622?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80"
    },
    {
      title: "Portraits personnels",
      description: "Des séances photo où vous vous sentirez à l'aise, que ce soit en pleine nature ou dans une ruelle charmante.",
      image: "https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80"
    },
    {
      title: "Valorisation de la Nouvelle-Aquitaine",
      description: "Nous mettons en valeur la beauté de notre région à travers des clichés captivants.",
      image: "https://images.unsplash.com/photo-1589994160839-163cd867cfe8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
    }
  ])
  </script>
  
  <style scoped>
  .services-section {
    padding: 4rem 0;
    background-color: white;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 3rem;
    font-size: 2.5rem;
    color: var(--color-primary);
  }
  
  .services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .service-card {
    background-color: var(--color-background);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .service-card:hover {
    transform: translateY(-5px);
  }
  
  .service-image {
    height: 200px;
    background-size: cover;
    background-position: center;
  }
  
  .service-content {
    padding: 1.5rem;
  }
  
  .service-content h3 {
    font-size: 1.5rem;
    color: var(--color-primary);
    margin-bottom: 0.5rem;
  }
  
  .service-content p {
    color: var(--color-secondary);
  }
  </style>