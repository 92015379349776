<template>
    <div class="album-detail-page">
      <h1>Album Details</h1>
      <div v-if="loading">Loading album details...</div>
      <div v-else-if="error">{{ error }}</div>
      <div v-else-if="album" class="album-content">
        <h2>{{ album.title }}</h2>
        <p>{{ album.description }}</p>
        <div class="album-metadata">
          <p>Created by: {{ album.creator }}</p>
          <p>Created on: {{ formatDate(album.createdAt) }}</p>
          <p>Last updated: {{ formatDate(album.updatedAt) }}</p>
        </div>
        <div class="photo-list">
          <h3>Photos in this album</h3>
          <div v-if="album.photos.length === 0">No photos in this album yet.</div>
          <div v-else class="photo-grid">
            <div v-for="photo in album.photos" :key="photo.id" class="photo-item">
              <img :src="photo.url" :alt="photo.title">
              <p>{{ photo.title }}</p>
            </div>
          </div>
        </div>
        <div class="album-actions">
          <button @click="editAlbum" class="edit-button">Edit Album</button>
          <button @click="deleteAlbum" class="delete-button">Delete Album</button>
        </div>
      </div>
      <div v-else>Album not found.</div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  
  export default {
    name: 'AlbumDetailPage',
    setup() {
      const route = useRoute()
      const router = useRouter()
      const store = useStore()
      const album = ref(null)
      const loading = ref(true)
      const error = ref(null)
  
      const fetchAlbumDetails = async () => {
        try {
          const albumId = route.params.id
          album.value = await store.dispatch('fetchAlbumById', albumId)
          loading.value = false
        } catch (err) {
          error.value = 'Failed to load album details. Please try again.'
          loading.value = false
        }
      }
  
      const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString()
      }
  
      const editAlbum = () => {
        router.push({ name: 'EditAlbumPage', params: { id: album.value.id } })
      }
  
      const deleteAlbum = async () => {
        if (confirm('Are you sure you want to delete this album?')) {
          try {
            await store.dispatch('deleteAlbum', album.value.id)
            router.push({ name: 'AlbumList' })
          } catch (err) {
            error.value = 'Failed to delete album. Please try again.'
          }
        }
      }
  
      onMounted(fetchAlbumDetails)
  
      return {
        album,
        loading,
        error,
        formatDate,
        editAlbum,
        deleteAlbum
      }
    }
  }
  </script>
  
  <style scoped>
  .album-detail-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  h1, h2, h3 {
    color: #333;
  }
  
  .album-content {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
  }
  
  .album-metadata {
    margin-top: 20px;
    font-style: italic;
    color: #666;
  }
  
  .photo-list {
    margin-top: 30px;
  }
  
  .photo-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  .photo-item {
    text-align: center;
  }
  
  .photo-item img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
  }
  
  .album-actions {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }
  
  button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .edit-button {
    background-color: #4CAF50;
    color: white;
  }
  
  .edit-button:hover {
    background-color: #45a049;
  }
  
  .delete-button {
    background-color: #f44336;
    color: white;
  }
  
  .delete-button:hover {
    background-color: #d32f2f;
  }
  </style>