<template>
    <div class="media-management-page">
      <h1>Gestion des Médias</h1>
      
      <div class="filters">
        <select v-model="filterType" @change="filterMedia">
          <option value="all">Tous les médias</option>
          <option value="image">Images</option>
          <option value="video">Vidéos</option>
        </select>
        <input 
          type="text" 
          v-model="searchQuery" 
          @input="filterMedia" 
          placeholder="Rechercher un média..."
        />
      </div>
  
      <div class="upload-section">
        <input type="file" ref="fileInput" @change="handleFileUpload" multiple accept="image/*,video/*" class="file-input" />
        <button @click="triggerFileInput" class="upload-btn">
          <i class="fas fa-upload"></i> Télécharger des médias
        </button>
      </div>
  
      <div class="media-grid">
        <div v-for="media in filteredMedia" :key="media.id" class="media-item">
          <img v-if="media.type === 'image'" :src="media.url" :alt="media.name" />
          <video v-else-if="media.type === 'video'" :src="media.url" controls></video>
          <div class="media-info">
            <span>{{ media.name }}</span>
            <span>{{ formatDate(media.uploadDate) }}</span>
          </div>
          <div class="media-actions">
            <button @click="editMedia(media)" class="edit-btn">
              <i class="fas fa-edit"></i>
            </button>
            <button @click="deleteMedia(media.id)" class="delete-btn">
              <i class="fas fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
  
      <div v-if="filteredMedia.length === 0" class="no-media">
        Aucun média trouvé.
      </div>
  
      <!-- Modal d'édition -->
      <div v-if="showEditModal" class="modal">
        <div class="modal-content">
          <h2>Modifier le média</h2>
          <form @submit.prevent="updateMedia">
            <div class="form-group">
              <label for="mediaName">Nom du média</label>
              <input v-model="editingMedia.name" id="mediaName" required>
            </div>
            <div class="form-group">
              <label for="mediaDescription">Description</label>
              <textarea v-model="editingMedia.description" id="mediaDescription"></textarea>
            </div>
            <div class="form-group">
              <label for="mediaAlbum">Album</label>
              <select v-model="editingMedia.albumId" id="mediaAlbum">
                <option v-for="album in albums" :key="album.id" :value="album.id">
                  {{ album.name }}
                </option>
              </select>
            </div>
            <div class="modal-actions">
              <button type="submit" class="save-btn">Enregistrer</button>
              <button @click="closeEditModal" class="cancel-btn">Annuler</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, computed } from 'vue'
  
  export default {
    name: 'MediaManagementPage',
    setup() {
      const media = ref([
        { id: 1, name: 'Place de la Bourse.jpg', type: 'image', url: '/placeholder.svg?height=200&width=300', uploadDate: '2023-05-15T14:30:00', albumId: 1 },
        { id: 2, name: 'Dune du Pilat.mp4', type: 'video', url: '/placeholder.svg?height=200&width=300', uploadDate: '2023-05-16T10:15:00', albumId: 2 },
        { id: 3, name: 'Vignobles Saint-Émilion.jpg', type: 'image', url: '/placeholder.svg?height=200&width=300', uploadDate: '2023-05-17T18:45:00', albumId: 1 },
        { id: 4, name: 'Arcachon.jpg', type: 'image', url: '/placeholder.svg?height=200&width=300', uploadDate: '2023-05-18T09:20:00', albumId: 2 },
      ])
  
      const albums = ref([
        { id: 1, name: 'Bordeaux by Night' },
        { id: 2, name: 'Côte Atlantique' },
      ])
  
      const filterType = ref('all')
      const searchQuery = ref('')
      const showEditModal = ref(false)
      const editingMedia = ref(null)
  
      const filteredMedia = computed(() => {
        return media.value.filter(item => {
          const matchesType = filterType.value === 'all' || item.type === filterType.value
          const matchesSearch = item.name.toLowerCase().includes(searchQuery.value.toLowerCase())
          return matchesType && matchesSearch
        })
      })
  
      const filterMedia = () => {
        // Cette fonction est appelée automatiquement grâce aux liaisons v-model et @input
      }
  
      const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' }
        return new Date(dateString).toLocaleDateString('fr-FR', options)
      }
  
      const handleFileUpload = (event) => {
        const files = event.target.files
        // Ici, vous implémenteriez la logique pour télécharger les fichiers
        console.log('Fichiers à télécharger:', files)
      }
  
      const triggerFileInput = () => {
        document.querySelector('.file-input').click()
      }
  
      const editMedia = (mediaItem) => {
        editingMedia.value = { ...mediaItem }
        showEditModal.value = true
      }
  
      const closeEditModal = () => {
        showEditModal.value = false
        editingMedia.value = null
      }
  
      const updateMedia = () => {
        // Ici, vous implémenteriez la logique pour mettre à jour le média
        const index = media.value.findIndex(item => item.id === editingMedia.value.id)
        if (index !== -1) {
          media.value[index] = { ...editingMedia.value }
        }
        closeEditModal()
      }
  
      const deleteMedia = (id) => {
        // Ici, vous implémenteriez la logique pour supprimer le média
        media.value = media.value.filter(item => item.id !== id)
      }
  
      return {
        media,
        filteredMedia,
        albums,
        filterType,
        searchQuery,
        showEditModal,
        editingMedia,
        filterMedia,
        formatDate,
        handleFileUpload,
        triggerFileInput,
        editMedia,
        closeEditModal,
        updateMedia,
        deleteMedia
      }
    }
  }
  </script>
  
  <style scoped>
  .media-management-page {
    padding: 2rem;
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: #2c3e50;
  }
  
  .filters {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  
  .filters select,
  .filters input {
    padding: 0.5rem;
    border: 1px solid #bdc3c7;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .upload-section {
    margin-bottom: 2rem;
  }
  
  .file-input {
    display: none;
  }
  
  .upload-btn {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 0.75rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .upload-btn:hover {
    background-color: #2980b9;
  }
  
  .media-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
  }
  
  .media-item {
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .media-item img,
  .media-item video {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .media-info {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;
    color: #34495e;
  }
  
  .media-actions {
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
  }
  
  .edit-btn,
  .delete-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    transition: color 0.3s;
  }
  
  .edit-btn {
    color: #3498db;
  }
  
  .edit-btn:hover {
    color: #2980b9;
  }
  
  .delete-btn {
    color: #e74c3c;
  }
  
  .delete-btn:hover {
    color: #c0392b;
  }
  
  .no-media {
    text-align: center;
    padding: 2rem;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    width: 500px;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .form-group input,
  .form-group textarea,
  .form-group select {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #bdc3c7;
    border-radius: 4px;
  }
  
  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .save-btn,
  .cancel-btn {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .save-btn {
    background-color: #2ecc71;
    color: white;
  }
  
  .save-btn:hover {
    background-color: #27ae60;
  }
  
  .cancel-btn {
    background-color: #e74c3c;
    color: white;
  }
  
  .cancel-btn:hover {
    background-color: #c0392b;
  }
  </style>