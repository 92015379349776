<template>
  <div class="services-page">
    <header class="hero">
      <div class="hero-content">
        <h1>Capturez l'Essence de La Rochelle</h1>
        <p>Des moments uniques, des souvenirs éternels</p>
        <a href="#services-section" class="btn-hero">Découvrir nos services</a>
      </div>
    </header>

    <main id="services-section" class="services-container">
      <section v-for="service in services" :key="service.id" :id="'service-' + service.id" class="service-section">
        <div class="service-content">
          <h2>{{ service.title }}</h2>
          <p>{{ service.description }}</p>
          <ul class="service-features">
            <li v-for="feature in service.features" :key="feature">{{ feature }}</li>
          </ul>
          <a :href="'#service-' + service.id" class="btn-service">En savoir plus</a>
        </div>
        <div class="service-image" :style="{ backgroundImage: `url(${service.image})` }">
          <div class="image-overlay"></div>
        </div>
      </section>
    </main>

    <section class="cta">
      <h2>Prêt à capturer vos moments uniques ?</h2>
      <p>Contactez-nous dès aujourd'hui pour discuter de votre projet</p>
      <router-link to="/contact" class="btn-cta">Contactez-nous</router-link>
    </section>
  </div>
</template>

<script>
// import { useRouter } from 'vue-router';

export default {
  name: 'ServicesPage',
  data() {
    return {
      services: [
        {
          id: 1,
          title: 'Photographie d\'événements',
          description: 'Immortalisez vos moments spéciaux avec notre expertise en photographie d\'événements. Que ce soit pour un mariage, une conférence ou un festival, nous capturons l\'essence de chaque instant.',
          features: [
            'Couverture complète de l\'événement',
            'Équipement professionnel de pointe',
            'Post-traitement expert des images',
            'Livraison rapide des photos'
          ],
          image: 'https://images.unsplash.com/photo-1511578314322-379afb476865?ixlib=rb-4.0.3&auto=format&fit=crop&w=1169&q=80'
        },
        {
          id: 2,
          title: 'Portraits personnels',
          description: 'Révélez votre personnalité unique à travers nos séances de portraits personnalisées. Que ce soit pour un usage professionnel ou personnel, nous créons des images qui vous ressemblent.',
          features: [
            'Séances en studio ou en extérieur',
            'Conseils sur le style et la pose',
            'Retouches professionnelles',
            'Plusieurs tenues et décors possibles'
          ],
          image: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-4.0.3&auto=format&fit=crop&w=688&q=80'
        },
        {
          id: 3,
          title: 'Valorisation de La Rochelle',
          description: 'Mettez en lumière la beauté de notre ville avec notre service de photographie dédié à La Rochelle. Des paysages côtiers aux monuments historiques, en passant par l\'ambiance unique de notre cité.',
          features: [
            'Connaissance approfondie de La Rochelle',
            'Captures de paysages spectaculaires',
            'Mise en valeur du patrimoine local',
            'Photographie aérienne disponible'
          ],
          image: 'https://images.unsplash.com/photo-1589570731442-f2c1a8e0ad7d?ixlib=rb-4.0.3&auto=format&fit=crop&w=1170&q=80'
        }
      ]
    }
  },
  mounted() {
    this.setupSmoothScroll();
  },
  methods: {
    setupSmoothScroll() {
      document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
          e.preventDefault();
          const targetId = this.getAttribute('href').substring(1);
          const targetElement = document.getElementById(targetId);
          if (targetElement) {
            targetElement.scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            });
          }
        });
      });
    }
  },
  errorCaptured(err, vm, info) {
    console.error('Une erreur s\'est produite dans ServicesPage:', err, info);
    // Vous pouvez rediriger vers une page d'erreur personnalisée ici si nécessaire
    // this.$router.push('/error');
    return false; // Empêche l'erreur de se propager davantage
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

.services-page {
  font-family: 'Poppins', sans-serif;
  color: #333;
  line-height: 1.6;
}

.hero {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('https://images.unsplash.com/photo-1564594874687-ae5a1d70a1b0?ixlib=rb-4.0.3&auto=format&fit=crop&w=1470&q=80');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  position: relative;
  overflow: hidden;
}

.hero-content {
  max-width: 800px;
  padding: 0 20px;
  z-index: 2;
}

.hero h1 {
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 1rem;
  animation: fadeInDown 1s ease-out;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.hero p {
  font-size: 1.8rem;
  margin-bottom: 2rem;
  animation: fadeInUp 1s ease-out 0.5s both;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.btn-hero {
  display: inline-block;
  background-color: #3498db;
  color: white;
  padding: 15px 30px;
  border-radius: 50px;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: bold;
  transition: all 0.3s ease;
  animation: fadeInUp 1s ease-out 1s both;
}

.btn-hero:hover {
  background-color: #2980b9;
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.services-container {
  max-width: 1200px;
  margin: 4rem auto;
  padding: 0 20px;
}

.service-section {
  display: flex;
  margin-bottom: 4rem;
  background-color: #f8f9fa;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.service-section:nth-child(even) {
  flex-direction: row-reverse;
}

.service-content {
  flex: 1;
  padding: 3rem;
}

.service-content h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #2c3e50;
}

.service-content p {
  margin-bottom: 1.5rem;
}

.service-features {
  list-style-type: none;
  padding: 0;
  margin-bottom: 1.5rem;
}

.service-features li {
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
  position: relative;
}

.service-features li::before {
  content: '✓';
  color: #3498db;
  position: absolute;
  left: 0;
}

.service-image {
  flex: 1;
  background-size: cover;
  background-position: center;
  position: relative;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4));
}

.btn-service {
  display: inline-block;
  background-color: #3498db;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.btn-service:hover {
  background-color: #2980b9;
}

.cta {
  background-color: #3498db;
  color: white;
  text-align: center;
  padding: 4rem 20px;
}

.cta h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.cta p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.btn-cta {
  display: inline-block;
  background-color: white;
  color: #3498db;
  padding: 15px 30px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn-cta:hover {
  background-color: #2980b9;
  color: white;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.5rem;
  }

  .hero p {
    font-size: 1.2rem;
  }

  .service-section,
  .service-section:nth-child(even) {
    flex-direction: column;
  }

  .service-content,
  .service-image {
    width: 100%;
  }

  .service-image {
    height: 200px;
  }
}
</style>