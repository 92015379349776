<template>
  <div class="not-found">
    <div class="content">
      <h1>404</h1>
      <h2>Page Non Trouvée</h2>
      <p>Oups ! La page que vous recherchez n'existe pas.</p>
      <p>Il semble que vous ayez pris un mauvais virage. Ne vous inquiétez pas, ça arrive aux meilleurs d'entre nous !</p>
      <router-link to="/" class="home-button">Retour à l'accueil</router-link>
    </div>
  </div>
</template>

<style scoped>
.not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f4f7fa;
  font-family: 'Roboto', sans-serif;
}

.content {
  text-align: center;
  padding: 2rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
}

h1 {
  font-size: 6rem;
  color: #3498db;
  margin: 0;
  font-family: 'Playfair Display', serif;
  line-height: 1;
}

h2 {
  font-size: 2rem;
  color: #2c3e50;
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: 'Playfair Display', serif;
}

p {
  font-size: 1.1rem;
  color: #34495e;
  margin-bottom: 1.5rem;
}

.home-button {
  display: inline-block;
  background-color: #3498db;
  color: white;
  text-decoration: none;
  padding: 12px 24px;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: bold;
  transition: all 0.3s ease;
}

.home-button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .content {
    padding: 1.5rem;
  }

  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1rem;
  }
}
</style>