<template>
  <header class="site-header">
    <div class="container">
      <nav>
        <div class="logo">
          <router-link to="/">
            <img
              src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/LOGO%20LAROCHELLE%2002%20-qxHZg1QzQHmpggXb5e1kI2HN7cpkfT.JPG"
              alt="Vivre la Rochelle Photographie - Logo avec un appareil photo stylisé en jaune et la silhouette de La Rochelle"
              class="logo-image"
            />
          </router-link>
        </div>
        <ul class="nav-links" :class="{ 'open': mobileMenuOpen }">
          <li><router-link to="/" exact>Accueil</router-link></li>
          <li><router-link to="/about">À propos</router-link></li>
          <li><router-link to="/services">Nos Services</router-link></li>
          <li><router-link to="/gallery">Galerie</router-link></li>
          <li><router-link to="/contact">Contact</router-link></li>
          <li v-if="isAuthenticated"><router-link to="/admin">Admin</router-link></li>
          <li v-if="isAuthenticated">
            <button @click="logout" class="logout-button">Déconnexion</button>
          </li>
          <li v-else><router-link to="/login" class="login-button">Connexion</router-link></li>
        </ul>
        <button class="mobile-menu-button" @click="toggleMobileMenu" aria-label="Menu">
          <span class="bar"></span>
          <span class="bar"></span>
          <span class="bar"></span>
        </button>
      </nav>
    </div>
  </header>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'SiteHeader',
  setup() {
    const store = useStore()
    const router = useRouter()
    const mobileMenuOpen = ref(false)

    const isAuthenticated = computed(() => store.getters.isAuthenticated)

    const logout = async () => {
      try {
        await store.dispatch('logout')
        router.push('/')
      } catch (error) {
        console.error('Erreur lors de la déconnexion:', error)
      }
    }

    const toggleMobileMenu = () => {
      mobileMenuOpen.value = !mobileMenuOpen.value
    }

    return {
      isAuthenticated,
      logout,
      mobileMenuOpen,
      toggleMobileMenu
    }
  }
}
</script>

<style scoped>
.site-header {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-image {
  max-height: 60px;
  width: auto;
}

.nav-links {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  align-items: center;
}

.nav-links li {
  margin-left: 1.5rem;
}

.nav-links a {
  color: #333;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.nav-links a:hover,
.nav-links a.router-link-active {
  color: #FFD700;
}

.login-button,
.logout-button {
  background-color: #FFD700;
  color: #000000;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.login-button:hover,
.logout-button:hover {
  background-color: #FFC800;
}

.mobile-menu-button {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 25px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.mobile-menu-button .bar {
  width: 30px;
  height: 3px;
  background-color: #333;
  transition: all 0.3s linear;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .mobile-menu-button {
    display: flex;
  }

  .nav-links.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .nav-links.open li {
    margin: 0;
    text-align: center;
  }

  .nav-links.open a,
  .nav-links.open button {
    display: block;
    padding: 1rem;
    width: 100%;
    text-align: center;
  }

  .logo-image {
    max-height: 50px;
  }
}
</style>