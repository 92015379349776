<template>
  <div id="app">
    <SiteHeader />
    <main>
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </main>
    <SiteFooter />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'

export default {
  name: 'App',
  components: {
    SiteHeader,
    SiteFooter
  },
  setup() {
    const store = useStore()
    const isLoading = ref(true)

    onMounted(async () => {
      try {
        await store.dispatch('checkAuth')
      } catch (error) {
        console.error('Erreur lors de la vérification de l\'authentification:', error)
      } finally {
        isLoading.value = false
      }
    })

    return {
      isLoading
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Roboto:wght@300;400;500;700&display=swap');
@import './assets/styles/variables.css';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--font-secondary);
  background-color: var(--color-background);
  color: var(--color-text);
  line-height: 1.6;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

main {
  min-height: calc(100vh - 160px); /* Adjust based on header and footer height */
  padding: 2rem 0;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 1rem;
  color: var(--color-text);
}

h1 {
  font-family: var(--font-primary);
  color: var(--color-primary);
}

a {
  color: var(--color-primary);
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: var(--color-primary-hover);
}

button {
  cursor: pointer;
  background-color: var(--color-primary);
  color: var(--color-text);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius);
  transition: var(--transition);
  font-size: 1rem;
  font-weight: 500;
}

button:hover {
  background-color: var(--color-primary-hover);
}

button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 215, 0, 0.3);
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 768px) {
  .container {
    padding: 0 0.5rem;
  }

  main {
    padding: 1rem 0;
  }
}
</style>